import React from "react";
import icon1 from "../assets/benefits/icon1.svg";
import icon2 from "../assets/benefits/icon2.svg";
import icon3 from "../assets/benefits/icon3.svg";
import icon4 from "../assets/benefits/icon4.svg";
import icon5 from "../assets/benefits/icon5.svg";
import icon6 from "../assets/benefits/icon6.svg";

const benefitsData = [
  {
    title: {
      en: "Round-the-clock support",
      ua: "Цілодобова підтримка",
    },
    text: {
      en: "We are always in touch, ready to provide advice and support 24/7 to make you feel confident.",
      ua: "Ми завжди на зв'язку, готові надавати консультації та підтримку 24/7, щоб ви почувалися впевнено.",
    },
    icon: icon1,
  },
  {
    title: {
      en: "Cargo safety",
      ua: "Збереження вантажу",
    },
    text: {
      en: "We guarantee the safety of your cargo, regardless of the circumstances.",
      ua: "Ми гарантуємо безпеку вашого вантажу, незалежно від обставин.",
    },
    icon: icon2,
  },
  {
    title: {
      en: "Reliable transport and professional drivers",
      ua: "Надійний транспорт та професійні водії",
    },
    text: {
      en: "Our fleet consists of modern vehicles, and our drivers are highly qualified professionals.",
      ua: "Наш автопарк складається з сучасних транспортних засобів, а наші водії – висококваліфіковані професіонали.",
    },
    icon: icon3,
  },
  {
    title: {
      en: "Favorable tariffs and transparent pricing",
      ua: "Вигідні тарифи і прозоре ціноутворення",
    },
    text: {
      en: "We provide tariffs that save you money and ensure a clear and transparent pricing system.",
      ua: "Надаємо тарифи, що економлять ваші кошти та забезпечуємо чітку і прозору систему ціноутворення.",
    },
    icon: icon4,
  },
  {
    title: {
      en: "Information support",
      ua: "Інформаційна підтримка",
    },
    text: {
      en: "We provide highly accurate and operational information about the progress of your freight transportation.",
      ua: "Надаємо високоточну та оперативну інформацію про хід Вашого вантажоперевезення.",
    },
    icon: icon5,
  },
  {
    title: {
      en: "Speed",
      ua: "Швидкість",
    },
    text: {
      en: "The well-coordinated work of our logisticians and managers allows us to carry out cargo transportation in the shortest possible time and at the highest level of quality.",
      ua: "Злагоджена робота наших логістів і менеджерів дозволяє виконувати вантажні перевезення в найкоротших термінах і на вищому рівні якості.",
    },
    icon: icon6,
  },
];

const Benefit = ({ isEn, title, text, icon }) => {
  return (
    <article className="w-full">
      <div className="flex gap-8 md:flex-col items-center">
        <img className="w-12 md:mr-auto" src={icon} alt=""></img>
        <h2 className="text-white md:mr-auto">{isEn ? title.en : title.ua}</h2>
      </div>
      <p className="text-th-grey mt-6">
        {isEn ? text.en : text.ua}
      </p>
    </article>
  );
};

const Benefits = ({ isEn }) => {
  return (
    <section id="benefits" className="bg-th-dark">
      <div className="p-default grid grid-cols-1 py-16 md:py-20 lg:py-32 benefits-container">
        <h3 className="text-white xl:col-span-6 md:mr-8 lg:mr-16 xl:mr-64">
          {isEn ? "Why choose us" : "чому обирають нас"}
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 lg:gap-20 mt-12 md:mt-16 xl:mt-4 lg:mt-24 xl:col-span-7">
          {benefitsData &&
            benefitsData.map((benefit, index) => (
              <Benefit key={index} isEn={isEn} {...benefit} />
            ))}
        </div>
      </div>
    </section>
  );
};

export default Benefits;
