import React, { useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import arrow_left from "../assets/arrow_left.svg";
import arrow_right from "../assets/arrow_right.svg";

const feedbackData = [
  {
    text: "Приємно працювати з надійними людьми. Все чітко і без порушень. На ділі - все як і на словах. Вчасна оплата. Особиста подяка Ірині за блискавичне вирішення питань. Надіюсь на подальшу співпрацю. Рекомендую!!!",
    title: "Томчишин Г. І., ФОП",
  },
  {
    text: "Дякую АТАСС-Бориспіль, ПрАТ за вчасну подачу авто і своєчасну доставку товару до клієнта. Товар було доставлено згідно всім правилам і температурним вимогам! Особлива подяка Ірині за супровід та надання потрібної інформації і оформлені. Рекомендуємо працювати з перевізником як з надійним партнером!",
    title: "АГРОСВІТ, НВФ ТОВ",
  },
  {
    text: "Дякую за роботу. Дуже порядний і відповідальний перевізник – з такими людьми приємно мати справи. Рекомендую до співпраці!",
    title: "Імперово Фудз, ООО",
  },
  {
    text: "Дуже вдячні превізникам. Надійно, якісно, швидко! Всі питання вирішувались миттєво! Рівень – ТОП! Рекомендую!",
    title: "Дворовенко Ю. М., ФОП",
  },
  {
    text: "Надійний та відповідальний перевізник, постановка авто під завантаження/розвантаження вчасна. Окрема подяка Денису, завжди на звʼязку, вічлива людина. Дякую за співпрацю.",
    title: "Могильов Транс, ТОВ",
  },
  {
    text: "Дякую Неллі за професіоналізм, вчасно поставлені авто! Сподіваємось на довгу продуктивну співпрацю. Рекомендую!",
    title: "Агро Овен, ООО",
  },
  {
    text: "Дякую Сергію за якісну роботу! Все оперативно та згідно домовленостей, професіонали своєї справи. Рекомендую як надійного та порядного перевізника!",
    title: "Українська логістика, ТОВ ТЕК",
  },
  {
    text: "Дякуємо Неллі за співпрацю. Порядний перевізник, добрий стан автопарку. Завжди на звʼязку.",
    title: "BCB, ТОВ",
  },
];

const FeedbackCard = ({ text, title }) => {
  return (
    <article className="w-full border-2 border-th-dark rounded-[40px] py-10 px-[20px] max-w-[340px] xl:max-w-[380px] mx-auto">
      <p>{text}</p>
      <p className="text-th-grey mt-8">{title}</p>
    </article>
  );
};

const Feedback = ({ isEn }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const slider = React.useRef(null);

  return (
    <section id="feedback">
      <div className="py-20 lg:py-32">
        <h3 className="mb-12 mx-[20px] md:mx-8 lg:mx-16">
          {isEn ? "feedback" : "відгуки"}
        </h3>
        <div className="flex items-center justify-center md:justify-end gap-x-4 p-default">
          <button onClick={() => slider?.current?.slickPrev()}>
            <img className="w-12" src={arrow_left} alt=""></img>
          </button>
          <button onClick={() => slider?.current?.slickNext()}>
            <img className="w-12" src={arrow_right} alt=""></img>
          </button>
        </div>
        <Slider
          ref={slider}
          {...settings}
          className="mt-12 md:mt-16 flex items-stretch justify-center"
        >
          {feedbackData &&
            feedbackData.map((slider, index) => (
              <FeedbackCard {...slider} key={index} />
            ))}
        </Slider>
      </div>
    </section>
  );
};

export default Feedback;
