import React from "react";

const Services = ({ isEn }) => {
  return (
    <section id="services">
      <div className="p-default py-16 md:py-20 lg:py-32">
        <h3>{isEn ? "Our services" : "наші послуги"}</h3>
        <div className="grid grid-cols-1 gap-10 mt-16 lg:grid-cols-2">
          <article className="w-full border-2 border-th-dark rounded-[40px] py-10 md:py-12 lg:p-16 px-[20px] md:px-8">
            <div className="flex gap-x-6 items-start">
              <h2>01</h2>
              <h2>
                {isEn
                  ? "Freight transportation in Ukraine"
                  : "Вантажоперевезення по Україні"}
              </h2>
            </div>
            <p className=" mt-8">
              {isEn
                ? "We are ready to deliver your cargo to any destination in Ukraine. With our technical base and experienced specialists, you can count on reliable and fast delivery."
                : "Ми готові доставити ваш вантаж до будь-якого місця призначення в Україні. З нашою технічною базою та досвідченими фахівцями ви можете розраховувати на надійну та швидку доставку."}
            </p>
          </article>
          <article className="w-full border-2 border-th-dark rounded-[40px] py-10 md:py-12 px-[20px] md:px-8 lg:p-16  lg:order-2">
            <div className="flex gap-x-6 items-start">
              <h2>02</h2>
              <h2>{isEn ? "Individual solutions" : "Індивідуальні рішення"}</h2>
            </div>
            <p className=" mt-8">
              {isEn
                ? "Regardless of the complexity of the order, our team offers optimal solutions and an individual approach to each client."
                : "Незалежно від складності замовлення, наша команда пропонує оптимальні рішення та індивідуальний підхід до кожного клієнта."}
            </p>
          </article>
          <article className="w-full border-2 border-th-red rounded-[40px] py-10 md:py-12 px-[20px] md:px-8 lg:p-16  bg-th-red lg:row-span-2">
            <div className="flex gap-x-6 items-start">
              <h2 className="text-white">03</h2>
              <h2 className="text-white">
                {isEn
                  ? "transportation and forwarding services"
                  : "транспортно-Експедиційні послуги"}
              </h2>
            </div>
            <p className="text-white mt-8">
              {isEn
                ? "We organize and ensure the transportation of goods under the transport forwarding contract from the departure point to the destination point."
                : "Ми організовуємо та забезпечуємо перевезення вантажу за договором транспортного експедування з пункту відправки до пункту призначення."}
            </p>
            <p className="text-white mt-8 lg:mt-12">
              {isEn
                ? "Services are available throughout Ukraine and include:"
                : "Послуги доступні по всій території України і включають:"}
            </p>
            <p className="text-white mt-4 lg:mt-8">
              {isEn
                ? "- direct control of loading and unloading of vehicles with a freight forwarder;"
                : "- безпосередній контроль завантаження та розвантаження тз експедитором; "}
            </p>
            <p className="text-white mt-4 lg:mt-8">
              {isEn
                ? "- online cargo tracking, including communication with the driver and carrier;"
                : "- супровід вантажу у режимі онлайн, включаючи спілкування з водієм та перевізником;"}
            </p>
            <p className="text-white mt-4 lg:mt-8">
              {isEn
                ? "- thorough and timely preparation of documentation."
                : "- ретельне та своєчасне оформлення документації."}
            </p>
          </article>
        </div>
      </div>
    </section>
  );
};

export default Services;
