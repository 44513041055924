import React from "react";
import icon1 from "../assets/partners/1.svg";
import icon2 from "../assets/partners/2.svg";
import icon3 from "../assets/partners/3.svg";
import icon4 from "../assets/partners/4.svg";
import icon5 from "../assets/partners/5.svg";
import icon6 from "../assets/partners/6.svg";
import icon7 from "../assets/partners/7.svg";
import icon8 from "../assets/partners/8.svg";

const icons = [icon1, icon2, icon3, icon4, icon5, icon6, icon7, icon8];

const Partners = ({ isEn }) => {
  return (
    <section id="partners">
      <div className="p-default pb-16 lg:pb-20 xl:pb-32">
        <h3>{isEn ? "WE ARE TRUSTED" : "НАМ ДОВІРЯЮТЬ"}</h3>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-16 mt-16 lg:mt-24 xl:mt-32">
          {icons.map((icon, index) => (
            <img className="m-auto" src={icon} alt=""></img>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Partners;
