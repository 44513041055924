import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { send } from "emailjs-com";

const Userform = ({ isEn }) => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const [sendSuccess, setSendSuccess] = useState(false);
  const sendFormData = async (data, error) => {
    send(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      data,
      process.env.REACT_APP_EMAILJS_PUBLIC_KEY
    );
    reset();
    setSendSuccess(true);
  };

  return (
    <form
      onSubmit={handleSubmit(sendFormData)}
      className="userform flex flex-col gap-y-4 max-w-sm w-full"
    >
      <input
        type="text"
        placeholder={isEn ? "Name" : "Імʼя"}
        {...register("first_name", { maxLength: 30, required: true })}
      />
      {errors.first_name?.type === "required" && (
        <span className="error-span">
          {isEn ? "Please, tell us your name" : "Будь ласка, вкажiть iм`я"}
        </span>
      )}
      <input
        type="tel"
        placeholder={isEn ? "Phone number" : "Номер телефону"}
        {...register("phone", {
          required: true,
          /* pattern:
            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, */
          pattern:
            /^(\+{1}\d{2,3}\s?[(]{1}\d{1,3}[)]{1}\s?\d+|\+?\d{2,3}\s{1}\d+|\+?\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}$/,
        })}
        aria-invalid={errors.email ? "true" : "false"}
      />
      {errors.phone?.type === "required" && (
        <span className="error-span">
          {isEn
            ? "Please, tell us your phone number"
            : "Будь ласка, вкажiть номер телефону"}
        </span>
      )}
      {errors.phone?.type === "pattern" && (
        <span className="error-span">
          {isEn
            ? "Please, enter a valid phone number"
            : "Будь ласка, вкажiть коректний номер телефону"}
        </span>
      )}
      <input
        placeholder={isEn ? "Company" : "Компанiя"}
        type=""
        defaultValue=""
        {...register("company")}
      />
      {sendSuccess && (
        <span className="success-span">
          {isEn
            ? "Thanks! We will contact you as soon as possible"
            : "Дякуємо! Ваш запит вiдправлено"}
        </span>
      )}
      <button type="submit" className="btn-primary mt-6">
        {isEn ? "send an inquiry" : "залишити заявку"}
      </button>
    </form>
  );
};

export default Userform;
