import AnchorLink from "react-anchor-link-smooth-scroll";
import { motion, AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import logo from "../assets/logo.svg";
import menu_open from "../assets/menu_open.svg";
import menu_close from "../assets/menu_close.svg";

const Navbar = ({ isEn, setEn }) => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const variants = {
    enter: {
      transition: { staggerChildren: 1, delayChildren: 0.2 },
    },
    exit: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  };

  return (
    <section id="navbar">
      <div className="p-default h-24 flex items-center justify-between">
        <a href="/">
          <img className="w-36 lg:w-48" src={logo} alt="ATASS Logistic"></img>
        </a>

        <div className="flex gap-x-4">
          <nav className="main-nav hidden md:flex items-center gap-x-6 mr-8 lg:mr-[10vw]">
            <AnchorLink href="#about">
              {isEn ? "about us" : "про нас"}
            </AnchorLink>
            <AnchorLink href="#services">
              {isEn ? "our services" : "послуги"}
            </AnchorLink>
            <AnchorLink href="#benefits">
              {isEn ? "why choose us" : "переваги"}
            </AnchorLink>
            <AnchorLink href="#feedback">
              {isEn ? "feedback" : "вiдгуки"}
            </AnchorLink>
          </nav>
          <div className="flex items-center gap-x-2">
            <button
              onClick={() => setEn(false)}
              className={!isEn ? "btn-lang-active" : "btn-lang-inactive"}
            >
              UA
            </button>
            <button
              onClick={() => setEn(true)}
              className={!isEn ? "btn-lang-inactive" : "btn-lang-active"}
            >
              EN
            </button>
          </div>
          {toggleMenu ? (
            <img
              className="w-8 cursor-pointer md:hidden"
              src={menu_close}
              onClick={() => setToggleMenu(false)}
              alt="Close menu"
            ></img>
          ) : (
            <img
              className="w-8 cursor-pointer md:hidden"
              src={menu_open}
              alt="Menu"
              onClick={() => setToggleMenu(true)}
            ></img>
          )}
        </div>

        <AnimatePresence>
          {toggleMenu && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              variants={variants}
              className="absolute w-full top-24 left-0 z-50 bg-th-dark"
            >
              <div
                className="px-6 py-12 py-default flex flex-col gap-y-8"
                initial="closed"
                animate="open"
              >
                <AnchorLink
                  className="navlink relative"
                  href="#about"
                  onClick={() => setToggleMenu(false)}
                >
                  {isEn ? "About us" : "Про нас"}
                </AnchorLink>
                <AnchorLink
                  className="navlink relative"
                  href="#services"
                  onClick={() => setToggleMenu(false)}
                >
                  {isEn ? "Our services" : "Послуги"}
                </AnchorLink>
                <AnchorLink
                  className="navlink relative"
                  href="#benefits"
                  onClick={() => setToggleMenu(false)}
                >
                  {isEn ? "Why choose us" : "Переваги"}
                </AnchorLink>
                <AnchorLink
                  className="navlink relative"
                  href="#feedback"
                  onClick={() => setToggleMenu(false)}
                >
                  {isEn ? "Feedback" : "Вiдгуки"}
                </AnchorLink>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </section>
  );
};

export default Navbar;
