import React from "react";
import logo from "../assets/logo.svg";
import mail from "../assets/mail.svg";
import phone from "../assets/phone.svg";
import geo from "../assets/geo.svg";
import Userform from "./Userform";

const Footer = ({ isEn }) => {
  return (
    <section id="footer" className="bg-th-dark">
      <div className="p-default pt-16 lg:pt-20 xl:pt-32 pb-12">
        <div className="md:flex gap-x-16 justify-between w-full">
          <div>
            <h3 className="text-white">
              {isEn
                ? "GET FREE CONSULTATION"
                : "отримати безкоштовну консультацію"}
            </h3>
            <p className="my-6 text-white">
              {isEn
                ? "Leave a request for feedback"
                : "Залиште заявку для зворотнього зв’язку"}
            </p>
          </div>
          <Userform isEn={isEn} />
        </div>

        <div className="flex flex-col gap-y-6 mt-16 md:mt-20 lg:mt-32 lg:flex-row lg:justify-between">
          <a href="/">
            <img
              className="w-36 lg:w-48 xl:w-64"
              src={logo}
              alt="ATASS Logistic"
            ></img>
          </a>
          <div className="lg:order-3">
            <a href="mailto:atass@atass.com.ua" rel="nofollow">
              <div className="flex items-center gap-x-4">
                <img className="w-7" src={mail} alt=""></img>
                <p className="text-white md:text-lg">atass@atass.com.ua</p>
              </div>
            </a>
            <a href="tel:+380670112077" rel="nofollow">
              <div className="flex items-center gap-x-4 mt-6">
                <img className="w-7" src={phone} alt=""></img>
                <p className="text-white md:text-lg">+38 067 011 20 77</p>
              </div>
            </a>
          </div>
          <div className="flex items-start gap-x-4 max-w-sm lg:order-2">
            <img className="w-7" src={geo} alt=""></img>
            <p className="text-white md:text-lg">
              {isEn
                ? "Kyiv region, Boryspil district, city Boryspil, St. Volodymyra Momota, 42"
                : "Київська область, Бориспільський район, місто Бориспіль, вул.Володимира Момота, 42"}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
