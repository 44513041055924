import { useState } from "react";
import Navbar from "./components/Navbar";
import Header from "./components/Header";
import About from "./components/About";
import Services from "./components/Services";
import Benefits from "./components/Benefits";
import Feedback from "./components/Feedback";
import Partners from "./components/Partners";
import Footer from "./components/Footer";

function App() {

  const [isEn, setEn] = useState(false)

  return (
    <section id="app" className="overflow-hidden">
      <Navbar isEn={isEn} setEn={setEn} />
      <Header isEn={isEn} />
      <About isEn={isEn} />
      <Services isEn={isEn} />
      <Benefits isEn={isEn} />
      <Feedback isEn={isEn} />
      <Partners isEn={isEn} />
      <Footer isEn={isEn} />
    </section>
  );
}

export default App;
