import React, { useState } from "react";
import img_sm from "../assets/header_sm.jpg";
import img_lg from "../assets/header_lg.jpg";
import PopupForm from "./PopupForm";

const Header = ({ isEn }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  return (
    <section id="start">
      <div className="md:hidden">
        <img className="w-full" src={img_sm} alt="ATASS Logistic"></img>
        <div className="p-default pt-16 flex flex-col gap-y-8">
          <h1>
            {isEn
              ? "Reliable cargo transportation in Ukraine from ATASS-Boryspil Logistics"
              : "Надійні вантажні перевезення по Україні від ATASS-Boryspil Logistic"}
          </h1>
          <p>
            {isEn
              ? "A wide range of services and a guarantee of the safety of your cargo."
              : "Широкий спектр послуг та гарантія безпеки вашого вантажу."}
          </p>
          <button
            onClick={() => setPopupOpen(true)}
            className="btn-primary w-full"
          >
            {isEn ? "send an inquiry" : "залишити заявку"}
          </button>
          <PopupForm isOpen={popupOpen} toggleOpen={setPopupOpen} isEn={isEn} />
        </div>
      </div>
      <div className="relative hidden md:flex fullheight flex-col bg-header bg-cover bg-top bg-no-repeat p-default py-16">
        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-neutral-900"></div>
        <h1 className="text-white mt-auto relative">
          {isEn
            ? "Reliable cargo transportation in Ukraine from ATASS-Boryspil Logistics"
            : "Надійні вантажні перевезення по Україні від ATASS-Boryspil Logistic"}
        </h1>
        <div className="flex justify-between items-center gap-x-16 mt-8 relative">
          <p className="text-white flex-grow">
            {isEn
              ? "A wide range of services and a guarantee of the safety of your cargo."
              : "Широкий спектр послуг та гарантія безпеки вашого вантажу."}
          </p>
          <button
            onClick={() => setPopupOpen(true)}
            className="btn-primary ml-auto min-w-[256px]"
          >
            {isEn ? "send an inquiry" : "залишити заявку"}
          </button>
        </div>
      </div>
    </section>
  );
};

export default Header;
