import Popup from "reactjs-popup";
import React, { useState } from "react";
import modal_close from "../assets/menu_close_grey.svg";
import Userform from "./Userform";

const PopupForm = ({ isOpen, toggleOpen, isEn }) => {
  return (
    <Popup
      open={isOpen}
      closeOnDocumentClick
      className="popup-modal"
      lockScroll
      onClose={() => toggleOpen(false)}
    >
      <div className="modal bg-th-dark pt-8 pb-12 px-6 md:px-16 max-w-lg flex flex-col">
        <div className="flex">
          <img
            className="cursor-pointer w-8 ml-auto"
            alt=""
            onClick={() => toggleOpen(false)}
            src={modal_close}
          />
        </div>
        <div className="mx-auto">
          <p className="text-[22px] md:text-[30px] text-white mb-12 mt-6">
            {isEn
              ? "Send an inquiry for feedback"
              : "Залиште заявку для зворотнього зв’язку"}
          </p>
          <Userform isEn={isEn} />
        </div>
      </div>
    </Popup>
  );
};

export default PopupForm;
