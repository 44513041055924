import React from "react";
import truck_sm from "../assets/truck_sm.jpg";
import truck_lg from "../assets/truck_lg.jpg";

const About = ({ isEn }) => {
  return (
    <section id="about">
      <div className="p-default pt-24 xl:pt-32">
        <div className="xl:grid xl:grid-cols-5">
          <h2>{isEn ? "About us" : "Про нас"}</h2>
          <div className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-8 lg:gap-x-12 xl:col-span-4 xl:mt-0">
            <article>
              <p>
                {isEn
                  ? "Since 2019, ATASS-Boryspil has been operating in the field of cargo transportation in Ukraine. At the initial stage of development, our company had only 4 truck tractors and 4 refrigerated semi-trailers."
                  : "З 2019 року АТАСС-Бориспіль розпочало свою діяльність у сфері вантажних перевезень по Україні. На початковому етапі розвитку наша компанія налічувала лише 4 тягачі та 4 напівпричепи-рефрижератори."}
              </p>
              <p className="mt-8">
                {isEn
                  ? "During each year, we developed rapidly and dynamically, increasing our vehicle fleet and working for results."
                  : "Протягом кожного року ми стрімко та динамічно розвивалися, збільшуючи автопарк та працюючи на результат."}
              </p>
            </article>
            <article>
              <p>
                {isEn
                  ? "Today, we are a large leading company that offers a wide range of quality services for freight transportation across Ukraine in intercity connections."
                  : "Сьогодні ми – велика провідна компанія, яка пропонує широкий спектр якісних послуг з вантажних перевезень по Україні в міжміських сполученнях."}
              </p>
              <img
                className="mt-8 md:hidden w-full"
                src={truck_sm}
                alt=""
              ></img>
              <p className="mt-8">
                {isEn
                  ? "ATASS-Boryspil's fleet operated under the name of a freight forwarding company, until 2022."
                  : "До 2022 року автопарк компанії ПрАТ «АТАСС-Бориспіль» працював під іменем транспортно-експедиційної компанії."}
              </p>
            </article>
            <article>
              <p>
                {isEn
                  ? "Since the beginning of February 2022, we have become an independent and autonomous company specializing in freight transportation in Ukraine."
                  : "З початку лютого 2022 року, ми стали самостійною та незалежною компанією, спеціалізуючись у вантажних перевезеннях по Україні."}
              </p>
              <p className="mt-8">
                {isEn
                  ? "Today, the company's fleet consists of 24 truck tractors and 23 semi-trailers with a carrying capacity of 20 tons, including 3 tilt semi-trailers and 20 refrigerated semi-trailers."
                  : "Сьогодні автопарк підприємства налічує 24 вантажних тягачів та 23 напівпричіпи з вантажопід’ємністю 20 тонн, включаючи 3 тентовані напівпричіпи та 20 напівпричіпів-рефрижераторів."}
              </p>
            </article>
          </div>
        </div>
        <div className="xl:hidden grid grid-cols-1 md:grid-cols-3 gap-8 lg:gap-x-12 mt-8 md:mt-16 lg:mt-24">
          <img
            className="hidden md:block w-full md:row-span-3 md:mt-1"
            src={truck_lg}
            alt=""
          ></img>
          <article className="mt-4 md:mt-1">
            <div className="w-full bg-th-red h-0 border-2 border-th-red">
              &nbsp;
            </div>
            <h2 className="mt-3">
              {isEn
                ? "Technology of precise control"
                : "Технологія точного контролю"}
            </h2>
          </article>
          <p className=" text-th-grey">
            {isEn
              ? "The refrigerated semi-trailers are equipped with the SCHMITZ CARGOBULL Telematics online program, which allows us to track the temperature along the entire route, and GPS monitoring allows us to fully control the location and movement of our vehicles."
              : "Напівпричепи-рефрижератори оснащені онлайн-програмою SCHMITZ CARGOBULL Telematics, яка дозволяє відстежувати температурний режим протягом всього маршруту, а GPS моніторинг дає змогу повністю контролювати розташування та переміщення наших транспортних засобів."}
          </p>
          <article className="mt-4 md:mt-1">
            <div className="w-full bg-th-red h-0 border-2 border-th-red">
              &nbsp;
            </div>
            <h2 className="mt-3">
              {isEn ? "Hygienic standards" : "Гігієнічні стандарти"}
            </h2>
          </article>
          <p className="text-th-grey">
            {isEn
              ? "All semi-trailers undergo regular sanitary treatment, ensuring safe conditions for food transportation."
              : "Всі напівпричепи піддаються регулярній санітарній обробці, забезпечуючи безпечні умови транспортування продуктів харчування."}
          </p>
          <article className="mt-4 md:mt-1">
            <div className="w-full bg-th-red h-0 border-2 border-th-red">
              &nbsp;
            </div>
            <h2 className="mt-3">{isEn ? "Insurance" : "Страхування"}</h2>
          </article>
          <p className="text-th-grey">
            {isEn
              ? "Our company has a voluntary civil liability insurance contract for road carriers, which guarantees your safety during cargo transportation."
              : "Наша компанія має договір добровільного страхування цивільної відповідальності автомобільних перевізників, що гарантує вашу безпеку під час вантажних перевезень."}
          </p>
        </div>
        <div className="grid-container hidden xl:grid grid-cols-11 gap-x-12 mt-8 md:mt-16 lg:mt-24">
          <div className="hidden md:block w-full md:row-span-3 md:mt-1 col-span-6 min-h-full bg-truck bg-cover bg-center bg-no-repeat"></div>
          <div className="grid grid-cols-2 gap-12 col-span-7">
            <article className="mt-4 md:mt-1">
              <div className="w-full bg-th-red h-0 border-2 border-th-red">
                &nbsp;
              </div>
              <h2 className="mt-3">
                {isEn
                  ? "Technology of precise control"
                  : "Технологія точного контролю"}
              </h2>
            </article>
            <p className=" text-th-grey">
              {isEn
                ? "The refrigerated semi-trailers are equipped with the SCHMITZ CARGOBULL Telematics online program, which allows us to track the temperature along the entire route, and GPS monitoring allows us to fully control the location and movement of our vehicles."
                : "Напівпричепи-рефрижератори оснащені онлайн-програмою SCHMITZ CARGOBULL Telematics, яка дозволяє відстежувати температурний режим протягом всього маршруту, а GPS моніторинг дає змогу повністю контролювати розташування та переміщення наших транспортних засобів."}
            </p>
            <article className="mt-4 md:mt-1">
              <div className="w-full bg-th-red h-0 border-2 border-th-red">
                &nbsp;
              </div>
              <h2 className="mt-3">
                {isEn ? "Hygienic standards" : "Гігієнічні стандарти"}
              </h2>
            </article>
            <p className=" text-th-grey">
              {isEn
                ? "All semi-trailers undergo regular sanitary treatment, ensuring safe conditions for food transportation."
                : "Всі напівпричепи піддаються регулярній санітарній обробці, забезпечуючи безпечні умови транспортування продуктів харчування."}
            </p>
            <article className="mt-4 md:mt-1">
              <div className="w-full bg-th-red h-0 border-2 border-th-red">
                &nbsp;
              </div>
              <h2 className="mt-3">{isEn ? "Insurance" : "Страхування"}</h2>
            </article>
            <p className=" text-th-grey">
              {isEn
                ? "Our company has a voluntary civil liability insurance contract for road carriers, which guarantees your safety during cargo transportation."
                : "Наша компанія має договір добровільного страхування цивільної відповідальності автомобільних перевізників, що гарантує вашу безпеку під час вантажних перевезень."}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
